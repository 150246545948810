import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/estado',
    pathMatch: 'full'
  },
  {
    path: 'stock',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'subfamilia',
    loadChildren: () => import('./pages/subfamilia/subfamilia.module').then( m => m.SubfamiliaModule),
    pathMatch: 'full'
  },
  {
    path: 'listaart',
    loadChildren: () => import('./pages/listaart/listaart.module').then( m => m.ListaartModule),
    pathMatch: 'full'
  },
  {
    path: 'cesta',
    loadChildren: () => import('./pages/cesta/cesta.module').then( m => m.CestaModule),
    pathMatch: 'full'
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./pages/pedidos/pedidos.module').then( m => m.PedidosModule),
    pathMatch: 'full'
  },
  {
    path: 'pedlista',
    loadChildren: () => import('./pages/pedlista/pedlista.module').then( m => m.PedlistaModule),
    pathMatch: 'full'
  },
  {
    path: 'informatica',
    loadChildren: () => import('./pages/software/software.module').then( m => m.SoftwareModule),
    pathMatch: 'full'
  },
  {
    path: 'web',
    loadChildren: () => import('./pages/web/web.module').then( m => m.WebModule),
    pathMatch: 'full'
  },
  {
    path: 'mantenimientos',
    loadChildren: () => import('./pages/mantenimientos/mantenimientos.module').then( m => m.MantenimientosModule),
    pathMatch: 'full'
  },
  {
    path: 'noticias',
    loadChildren: () => import('./pages/noticias/noticias.module').then( m => m.NoticiasModule),
    pathMatch: 'full'
  },
  {
    path: 'copisteria',
    loadChildren: () => import('./pages/copisteria/copisteria.module').then( m => m.CopisteriaModule),
    pathMatch: 'full'
  },
  {
    path: 'pisa',
    loadChildren: () => import('./pages/pisa/pisa.module').then( m => m.PisaModule),
    pathMatch: 'full'
  },
  {
    path: 'pibo',
    loadChildren: () => import('./pages/pibo/pibo.module').then( m => m.PiboModule),
    pathMatch: 'full'
  },
  {
    path: 'mairena',
    loadChildren: () => import('./pages/mairena/mairena.module').then( m => m.MairenaModule),
    pathMatch: 'full'
  },
  {
    path: 'politicas',
    loadChildren: () => import('./pages/politicas/politicas.module').then( m => m.PoliticasModule),
    pathMatch: 'full'
  },
  {
    path: 'facturas',
    loadChildren: () => import('./pages/facturas/facturas.module').then( m => m.FacturasModule),
    pathMatch: 'full'
  },
  {
    path: 'listasdeseo',
    loadChildren: () => import('./pages/listasdeseo/listasdeseo.module').then( m => m.ListasdeseoModule),
    pathMatch: 'full'
  },
  {
    path: 'stripe',
    loadChildren: () => import('./pages/stripe/stripe.module').then( m => m.StripeModule),
    pathMatch: 'full'
  },
  {
    path: 'descargas',
    loadChildren: () => import('./pages/descargas/descargas.module').then( m => m.DescargasModule),
    pathMatch: 'full'
  },
  {
    path: '**',
    loadChildren: () => import('./pages/estado/estado.module').then( m => m.EstadoModule),
    pathMatch: 'full'
  }
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
