import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserData } from './user-data';

class PostObj {
}


@Injectable({
  providedIn: 'root'
})
export class Funciones{

  public logged;
  public username;
  public usermail;
  public uservalor;
  public uservalor2;
  public pages: Array<{title: string, visible: string}>;

  public APPSELECT  ="pica";

  public emailactivo; // no modificable
  public CLIENTES_CONTACTO;
  public CLIENTES_RAZSOCIAL;
  public CLIENTES_CONTRASENA;
  public CLIENTES_TELEFONO1;

  public CLIENTES_DIRECCION;
  public CLIENTES_POBLACION;
  public CLIENTES_PROVINCIA;
  public CLIENTES_CPOSTAL;
  public CLIENTES_NIF;

  public CODIGOTRABAJADOR;
  public NOMBRETRABAJADOR;
  public imprimir = false;
  public btatras = false;

  public muestragif;
  public muestraerror;
  public ESPERAME="0";
  
  public lower = null;
  public upper = null;

  public usuarioregistroarray;

  cabecera = {};

  httpHeader: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      "responseType": "text"
  }
}

  httpHeader2: {
    headers: {
      'Content-Type': 'application/json'
  }
}

 httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };

/*  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type' : 'Origin, X-Requested-With, Content-Type, Accept, Authorization' })
    
    
  };
 */
  
  postData = [];



  private NumeroPedido;
  private HoraPedido;
  private ImportePedido;
  

  constructor(
    private userData: UserData,
    private http: HttpClient,
    private storage: Storage,
     private router: Router
  ) {

 

  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  pago


   
  setNumeroPedido(value){
    this.NumeroPedido = value;
   }
   
   getNumeroPedido(){
    return this.NumeroPedido;
   }

  setFechaPedido(value){
    this.HoraPedido = value;
   }
   
   getFechaPedido(){
    return this.HoraPedido;
   }

  setImportePedido(value){
    this.ImportePedido = value;
   }
   
   getImportePedido(){
    return this.ImportePedido;
   }

   setRegistro(value){
    this.usuarioregistroarray = value;
   }
   
   getRegistro(){
    return this.usuarioregistroarray;
   }


  
  logeado() {
    return new Promise((resolve, reject) => {
     
      
      this.storage.get('email').then((User) => {
        this.storage.get('pass').then((Pass) => {
         
     
              var datosacceso;
              var datoscargados3;



                  if (User) {
      
                    this.getAcceso2(User, Pass).then(
                        data => {
                                  datosacceso = data;

                                  

                                  for (let usu of datosacceso) {
                          
                                    //this.userData.username = usu["CLIENTES_RAZSOCIAL"];
                                    this.userData.emailactivo = usu["CLIENTES_EMAIL"];

                                    //console.log(this.userData.emailactivo  + " = este es");
                                                    
                                    this.userData.logged = true;
                                    this.userData.setusername(this.userData.username);
                                    this.userData.setusermail(this.userData.emailactivo); 
                                    this.userData.CLIENTES_RAZSOCIAL= this.userData.username;
                          
                                    this.userData.cerrarcookie();
                                    this.userData.emailactivo = usu["CLIENTES_EMAIL"];
                    
                                    if (this.userData.username!=''){

                                      this.obtenernumerocesta( this.userData.emailactivo).then(
                                        data => {
                                          datoscargados3 = data;
                                            for (let dat of datoscargados3) {
                                              //console.log("Obtiene valorDATA = " + dat["valor"]);
                                              
                                              this.userData.setValorcesta(dat["valor"]);


                                              this.setSelect(this.userData.emailactivo).then(
                                                data2 => {
                                                
                                                var usuariodat;
                                        
                                                usuariodat = data2;
                                        
                                                for (let usu3 of usuariodat) {
                                        
                                                  this.userData.CLIENTES_CONTACTO = usu3["CLIENTES_RAZSOCIAL"]; 
                                                  this.userData.CLIENTES_RAZSOCIAL = usu3["CLIENTES_RAZSOCIAL"];   
                                                  this.userData.CLIENTES_CONTRASENA = usu3["CLIENTES_CONTRASENA"];   
                                                  this.userData.CLIENTES_EMAIL = usu3["CLIENTES_EMAIL"]; 
                                                  this.userData.CLIENTES_TELEFONO1 = usu3["CLIENTES_TELEFONO1"]; 
                                                  this.userData.CLIENTES_DIRECCION = usu3["CLIENTES_DIRECCION"];   
                                                  this.userData.CLIENTES_POBLACION = usu3["CLIENTES_POBLACION"];   
                                                  this.userData.CLIENTES_PROVINCIA = usu3["CLIENTES_PROVINCIA"];   
                                                  this.userData.CLIENTES_CPOSTAL = usu3["CLIENTES_CPOSTAL"];   
                                                  this.userData.CLIENTES_NIF = usu3["CLIENTES_NIF"];  
                                                  this.userData.CLIENTES_DIRECCION_ENTREGA = usu3["CLIENTES_DIRECCION_ENTREGA"];  
                                                  
                                                }

                                       

                                                resolve('some sucess stuff');
                                                reject('some fail stuff');
                                        
                                                                 
                                              }
                                            )
                                            .catch(
                                              error => {
                                                //console.log(error);
                                              }
                                            )

                                            
                                            }
                                      })
                                     
                                    }
                                  }//fin del for
                                })
                             }
                             
                            })  
                          })
                        
      });

                      
  }
  
 pagostripe(amount,token){

  
  const promise = new Promise((resolve, reject) => {
    const URL ="https://www.tricenter.es/recursos/pedidos.php/stripe";
    this.http
    .post<PostObj[]>(URL, { amount: amount , token: token },this.httpHeader)
    .toPromise()
    .then((res: any) => {
      this.postData = res.map((res: any) => {

        //console.log(this.postData);
        //console.log(token)
        alert('Payment Success!!');

        return res;
      });
      resolve(res);
    },
      err => {
        reject(err);
      }
    );
});
return promise;



 }




  getAcceso2(email,contrasena) {

    var dataito = {} ;
    dataito["email"] =  email;
    dataito["pass"] = contrasena;

    
    const promise = new Promise((resolve, reject) => {
      const URL ="https://www.tricenter.es/recursos/usuario.php/usuariopost";
      this.http
      .post<PostObj[]>(URL,dataito,this.httpHeader)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;
}

sumadescarga(id,numero) {

  const promise = new Promise((resolve, reject) => {
    const URL ="https://www.tricenter.es/recursos/descargas.php/sumadescarga/"+id+"/"+numero;
    this.http
      .get<PostObj[]>(URL)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;
}



getdescargas() {

  var dataito = {} ;
  const promise = new Promise((resolve, reject) => {
    const URL ="https://www.tricenter.es/recursos/descargas.php/getdescargas";
    this.http
    .post<PostObj[]>(URL,dataito,this.httpHeader)
    .toPromise()
    .then((res: any) => {
      this.postData = res.map((res: any) => {
        return res;
      });
      resolve(res);
    },
      err => {
        reject(err);
      }
    );
});
return promise;
}




  getAcceso22(email,contrasena) {
      //console.log("https://www.tricenter.es/recursos/usuario.php/usuario/"+email+"/"+contrasena);
      const promise = new Promise((resolve, reject) => {
        const URL ="https://www.tricenter.es/recursos/usuario.php/usuario/"+email+"/"+contrasena;
        this.http
          .get<PostObj[]>(URL)
          .toPromise()
          .then((res: any) => {
            this.postData = res.map((res: any) => {
              return res;
            });
            resolve(res);
          },
            err => {
              reject(err);
            }
          );
      });
      return promise;
    }
  

    getlistas() {
      //console.log("https://www.tricenter.es/recursos/usuario.php/getlistas");
      const promise = new Promise((resolve, reject) => {
        const URL ="https://www.tricenter.es/recursos/usuario.php/getlistas";
        this.http
          .get<PostObj[]>(URL)
          .toPromise()
          .then((res: any) => {
            this.postData = res.map((res: any) => {
              return res;
            });
            resolve(res);
          },
            err => {
              reject(err);
            }
          );
      });
      return promise;
    }
  

  
    getlistasarticulos(articulo,codigo,accion) {
      //console.log("https://www.tricenter.es/recursos/usuario.php/getlistasdelarticulo/" + articulo + "/" + codigo + "/" + this.userData.emailactivo + "/" + accion);
      const promise = new Promise((resolve, reject) => {
        const URL ="https://www.tricenter.es/recursos/usuario.php/getlistasdelarticulo/" + articulo + "/" + codigo + "/" + this.userData.emailactivo + "/" + accion;
        this.http
          .get<PostObj[]>(URL)
          .toPromise()
          .then((res: any) => {
            this.postData = res.map((res: any) => {
              return res;
            });
            resolve(res);
          },
            err => {
              reject(err);
            }
          );
      });
      return promise;
    }
  

    checkemail(email) {
      //console.log("https://www.tricenter.es/recursos/usuario.php/checkemail/"+email);
      const promise = new Promise((resolve, reject) => {
        const URL ="https://www.tricenter.es/recursos/usuario.php/checkemail/"+email;
        this.http
          .get<PostObj[]>(URL)
          .toPromise()
          .then((res: any) => {
            this.postData = res.map((res: any) => {
              return res;
            });
            resolve(res);
          },
            err => {
              reject(err);
            }
          );
      });
      return promise;
    }
  


	gettema(){
    //console.log("https://www.tricenter.es/recursos/index.php/tema");
    const promise = new Promise((resolve, reject) => {
      const URL ="https://www.tricenter.es/recursos/index.php/tema";
      this.http
        .get<PostObj[]>(URL)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
    }

	getsubtema(id){
    //console.log("https://www.tricenter.es/recursos/index.php/subtema/" + id);
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/index.php/subtema/"+id;
      this.http
        .get<PostObj[]>(URL)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
	}

	getlistart(dataito){
    //console.log("********************************************************");
    //console.log("https://www.tricenter.es/recursos/index.php/listart");
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/index.php/listart";
      this.http
        .post<PostObj[]>(URL,dataito,this.httpHeader)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }


  

  apuntameanewletter(dataito){
    //console.log("********************************************************");
    //console.log("https://www.tricenter.es/recursos/usuario.php/newletter");
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/usuario.php/newletter";
      this.http
        .post<PostObj[]>(URL,dataito,this.httpHeader)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }
  
  setUpdate(dataito){
    //console.log("********************************************************");
    //console.log("https://www.tricenter.es/recursos/usuario.php/editarusuario");
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/usuario.php/editarusuario";
      this.http
        .post<PostObj[]>(URL,dataito,this.httpHeader)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }
  
  

	getBuscar(dataito){
    //console.log("********************************************************");
    //console.log("https://www.tricenter.es/recursos/index.php/buscar");
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/index.php/buscar";
      this.http
        .post<PostObj[]>(URL,dataito,this.httpHeader)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
	}

  getBuscarconfiltros(valor){

    //console.log("********************************************************");
    //console.log("https://www.tricenter.es/recursos/index.php/buscar/" + valor);
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/index.php/buscar/" + valor;
      this.http
        .get<PostObj[]>(URL)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }
  
	getofertas(valor,){
	//console.log("https://www.tricenter.es/recursos/index.php/ofertas/"+valor);
  	return new Promise(
  		resolve=>{
  		   	this.http.get("https://www.tricenter.es/recursos/index.php/ofertas/"+valor)
  			//.map(res=> res.json())
  			.subscribe(
  				data => {
  					resolve(data);
  				},
  				err=>{
  					//console.log(err);
  				}
  			)
  		}
  	);
  }
  

  getlistapredefinida(valor){
    if (this.userData.emailactivo==""){
      //console.log("https://www.tricente.es/recursos/index.php/listas/"+valor+"/"+"654321123456");
      return new Promise(
        resolve=>{
             this.http.get("https://www.tricenter.es/recursos/index.php/listas/"+valor+"/654321123456")
          //.map(res=> res.json())
          .subscribe(
            data => {
              resolve(data);
            },
            err=>{
              //console.log(err);
            }
          )
        }
      );
    
      
    }else{
      //console.log("https://www.tricenter.es/recursos/index.php/listas/"+valor+"/"+this.userData.emailactivo);
      return new Promise(
        resolve=>{
             this.http.get("https://www.tricenter.es/recursos/index.php/listas/"+valor+"/"+this.userData.emailactivo)
          //.map(res=> res.json())
          .subscribe(
            data => {
              resolve(data);
            },
            err=>{
              //console.log(err);
            }
          )
        }
      );
    }
  }
	getBuscar_anterior(valor){
	//console.log("https://www.tricenter.es/recursos/buscar/"+valor);
  	return new Promise(
  		resolve=>{
  		   	this.http.get("https://www.tricenter.es/recursos/buscar/"+valor)
  			//.map(res=> res.json())
  			.subscribe(
  				data => {
  					resolve(data);
  				},
  				err=>{
  					//console.log(err);
  				}
  			)
  		}
  	);
	}

  
	pagomediantebanco(){
			return new Promise(
	  		resolve=>{
	  		   	this.http.get("https://www.tricenter.es/recursos/cesta.php/mediantebanco/" + this.userData.emailactivo)
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}
	  			)
	  		}
	  	);
	}

  
	getBuscar2(valor, lower, upper){
		this.storage.set('valorabuscar', valor);
    this.storage.set('orden', 'ASC');
    this.storage.set('menor', lower);
    this.storage.set('myor', upper);
		//console.log("https://www.tricenter.es/recursos/cuadroDeBusqueda/"+valor+"/ARTICULOS_PENDIENTE_ENTREGAR/ASC/"+lower+"/"+upper);
	  	return new Promise(
	  		resolve=>{
	  		   	this.http.get("https://www.tricenter.es/recursos/cuadroDeBusqueda/"+valor+"/ARTICULOS_PENDIENTE_ENTREGAR/ASC/"+lower+"/"+upper)
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}
	  			)
	  		}
	  	);
	}

  	
	noticias(){

		//console.log("https://www.tricenter.es/recursos/index.php/noticias");
	  	return new Promise(
	  		resolve=>{
	  		   	this.http.get("https://www.tricenter.es/recursos/index.php/noticias")
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}
	  			)
	  		}
	  	);
	}

  
	addRegistro(data){
	  	return new Promise(
	  		resolve=>{
	  			////console.log("El array DESPUES" + data);
	  		   	this.http.put("https://www.tricenter.es/recursos/usuario.php/add", data)
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}

	  			)
	  		}
	  	);
	}

	updToken(data){
	  	return new Promise(
	  		resolve=>{
	  		   	this.http.put("https://www.tricenter.es/recursos/usuario.php/updToken", data)
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}

	  			)
	  		}
	  	);
	}
	
	setSelect(id){
    //console.log("https://www.tricenter.es/recursos/usuario.php/sel/"+id+"/oka");

    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/usuario.php/sel/"+id+"/oka";
      this.http
        .get<PostObj[]>(URL)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
	}



	addUpdate(data){
	  	return new Promise(
	  		resolve=>{
	  			////console.log("El array DESPUES" + data);
	  		   	this.http.put("https://www.tricenter.es/recursos/usuario.php/upd", data)
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);

	  				},
	  				err=>{
	  					//console.log(err);
	  				}

	  			)
	  		}
	  	);
	}

/*----------------------------------------------------------------
---------------------------CESTA DE COMPRA------------------------
----------------------------------------------------------------*/
	cupon(codigo){
    //console.log("https://www.tricenter.es/recursos/cesta.php/cupon/"+codigo);
	  	return new Promise(
	  		resolve=>{
	  		   	this.http.get("https://www.tricenter.es/recursos/cesta.php/cupon/"+codigo)
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}

	  			)
	  		}
	  	);
	}

	delCesta(codigo, email){
	  	return new Promise(
	  		resolve=>{
	  		   	this.http.get("https://www.tricenter.es/recursos/cesta.php/delarticulo/"+codigo+"/"+email+"/oka")
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}

	  			)
	  		}
	  	);
	}
	eddCesta(codigo,  fecha, unidades, precio, email){
	  	return new Promise(
	  		resolve=>{
	  		   	this.http.get("https://www.tricenter.es/recursos/cesta.php/edd/"+codigo+"/"+fecha+"/"+unidades+"/"+precio+"/"+email+"/oka")
	  			//.map(res=> res.json())
	  			.subscribe(
	  				data => {
	  					resolve(data);
	  				},
	  				err=>{
	  					//console.log(err);
	  				}

	  			)
	  		}
	  	);
	}

		addCesta(dataito){

      //console.log("********************************************************");
      //console.log("https://www.tricenter.es/recursos/cesta.php/add");
      const promise = new Promise((resolve, reject) => {
        const URL = "https://www.tricenter.es/recursos/cesta.php/add";
        this.http
          .post<PostObj[]>(URL,dataito,this.httpHeader)
          .toPromise()
          .then((res: any) => {
            this.postData = res.map((res: any) => {
              //console.log(res);
              return res;
            });
            resolve(res);
          },
            err => {
              reject(err);
            }
          );
      });
      return promise;
  	
	}

	obtenernumerocesta(email){
    //console.log("https://www.tricenter.es/recursos/cesta.php/obtener/"+email+"/oka");
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/cesta.php/obtener/"+email+"/oka";
      this.http
        .get<PostObj[]>(URL)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
	}
	editarcesta(email){
    //console.log("https://www.tricenter.es/recursos/cesta.php/editar/"+email+"/oka");
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/cesta.php/editar/"+email+"/oka";
      this.http
        .get<PostObj[]>(URL)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }
  
  estadocesta(email){
    //console.log("https://www.tricenter.es/recursos/cesta.php/estadocesta/"+email+"/oka");
    const promise = new Promise((resolve, reject) => {
      const URL = "https://www.tricenter.es/recursos/cesta.php/estadocesta/"+email+"/oka";
      this.http
        .get<PostObj[]>(URL)
        .toPromise()
        .then((res: any) => {
          this.postData = res.map((res: any) => {
            return res;
          });
          resolve(res);
        },
          err => {
            reject(err);
          }
        );
    });
    return promise;
	}


cambiarestadopedido(numero,codigo,estado){

  //console.log("https://www.tricenter.es/recursos/pedidos.php/cambiarestadopedido/" + numero + "/"  + codigo + "/" + estado + "");
  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/pedidos.php/cambiarestadopedido/" + numero + "/"  + codigo + "/" + estado + "";
    this.http
      .get<PostObj[]>(URL)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;
 
}



/*----------------------------------------------------------------
---------------------------FIN DE COMPRA------------------------
----------------------------------------------------------------*/
async addPedido(dataito){
  //console.log("********************************************************");
  //console.log("https://www.tricenter.es/recursos/pedidos.php/grabarpedido");
  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/pedidos.php/grabarpedido";
    this.http
      .post<PostObj[]>(URL,dataito,this.httpHeader)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;
}

async addPedidobanco(dataito){
  //console.log("********************************************************");
  //console.log("https://www.tricenter.es/recursos/pedidos.php/grabarpedidobanco");
  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/pedidos.php/grabarpedidobanco";
    this.http
      .post<PostObj[]>(URL,dataito,this.httpHeader)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;
}

/*----------------------------------------------------------------
---------------------------FIN DE COMPRA------------------------
----------------------------------------------------------------*/
async subeimagen(dataito){

  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/noticias.php/subeimg";
    this.http
      .post<PostObj[]>(URL,dataito,this.httpHeader)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;
}

/*
async addPedido(data){

  //console.log("Empieza la grabacion " + data["ped_cod"] +  " ");

    return new Promise(
      resolve=>{
           this.http.put("https://www.tricenter.es/recursos/pedidos.php/grabarpedido", data,this.httpHeader )
        .subscribe(
          data => {
            resolve(data);
          },
          err=>{
            //console.log(err);
          }

        )
      }
    );
}
*/

getfacturas(email){
  //console.log("https://www.tricenter.es/recursos/facturas.php/facturas/" +email+ "/oka");
  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/facturas.php/facturas/" +email+ "/oka";
    this.http
      .get<PostObj[]>(URL)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;

}


getlistasdedeseos(email){
  //console.log("https://www.tricenter.es/recursos/listasdeseo.php/get/" +email+ "/oka");
  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/listasdeseo.php/get/" +email+ "/oka";
    this.http
      .get<PostObj[]>(URL)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;

}


getpedidosanteriores(email){
  //console.log("https://www.tricenter.es/recursos/pedidos.php/editarcabadm/" +email+ "/oka");
  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/pedidos.php/editarcabadm/" +email+ "/oka";
    this.http
      .get<PostObj[]>(URL)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;

}


getpedidoanterior(numero){

  //console.log("https://www.tricenter.es/recursos/pedidos.php/editarlin/"+numero+"/oka");
  const promise = new Promise((resolve, reject) => {
    const URL = "https://www.tricenter.es/recursos/pedidos.php/editarlin/"+numero+"/oka";
    this.http
      .get<PostObj[]>(URL)
      .toPromise()
      .then((res: any) => {
        this.postData = res.map((res: any) => {
          return res;
        });
        resolve(res);
      },
        err => {
          reject(err);
        }
      );
  });
  return promise;

}




/*-----------------------------------------------------------------------------------------
 -------------------  PUSH --------------------------------------------------------------
 --------------------------------------------------------------------------------------


enviarpush(valor){ 
    return new Promise(
      resolve=>{
        //console.log("Email " + valor["email"]);
        //console.log("Titulo " + valor["titulo"]);
        //console.log("Mensaje " + valor["mensaje"]);
           this.http.put("https://www.tricenter.es/recursos/push.php/mandar", valor)
        .map(res=> res.json())
        .subscribe(
          data => {
            //console.log(data);
          },
          err=>{
            //console.log(err);
          }

        )
      }
    );
}
*/  

}
