import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { HTTP } from '@ionic-native/http/ngx';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SeleccPage } from './pages/selecc/selecc';
import { AvisoPage } from './pages/aviso/aviso';
import { FavoritosPage } from './pages/favoritos/favoritos';
import { LoginPage } from './pages/login/login';
import { NoticiaPage } from './pages/noticia/noticia';
//import { PayPal } from '@ionic-native/paypal/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@NgModule({
  exports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  imports: [

    FormsModule, 
    ReactiveFormsModule,
    CKEditorModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [
    AppComponent,
    SeleccPage,
    AvisoPage,    
    FavoritosPage,
    LoginPage,
    NoticiaPage
  ],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    SocialSharing,
    HTTP,
    //PayPal,
    { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    SeleccPage,
    AvisoPage,
    FavoritosPage,
    LoginPage,
    NoticiaPage
  ]
})
export class AppModule {}
