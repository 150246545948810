import { AfterViewInit, Component } from '@angular/core';
import { Config, ModalController, NavParams } from '@ionic/angular';
import { ConferenceData } from '../../providers/conference-data';
import { Router } from '@angular/router';
import { UserData } from '../../providers/user-data';
import { Funciones } from '../../providers/funciones';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { Storage } from '@ionic/storage';
import { ViewChild } from '@angular/core';
import { AlertController} from '@ionic/angular';


@Component({
  selector: 'page-login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
})

export class LoginPage implements AfterViewInit {
  @ViewChild('inputId', {static: false}) ionInput: { setFocus: () => void; };
  @ViewChild('pass', {static: false}) Pass: { setFocus: () => void; };

  public loginForm: FormGroup;

  selectedRadioGroup:any;
  //Get value on ionSelect on IonRadio item
  selectedRadioItem:any;

  arttemas;
  artsubtemas;
  usuariodatos;
  id;
  subtema;
  code;
  email;
  contrasena;
  usuarioacceso;
  datosacceso;
  datoscargados5;
  valorcesta;
  usuario;

  pages: Array<{title: string, component: any}>;

  sinpass;

 formulario = 
 {
  valor: ''
}

login: any = {
  email: '',
  razsocial: '',
  password: '',
  contacto: '',
  telefono1: '',
  direccion: '',
  poblacion: '',
  provincia: '',
  cpostal: '',
  direccion_envio: '',
  pais: '',
  nif: '',
  grupos: ''
};


todo = {}
 
setFocusOnInput() {
  this.ionInput.setFocus();
}

setFocus(nextElement) {
  nextElement.setFocus(); //For Ionic 4

}


  constructor(
    public alertCtrl: AlertController,
    private storage: Storage,
    public fb: FormBuilder,
    public funciones: Funciones,
    public userData: UserData,
    public router: Router,
    public confData: ConferenceData,
    private config: Config,
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) {
    
  }

  loginUser() {


  }

  ngOnDestroy(){

  }

  async ngOnInit(){

          // capturamos la url
          var loc = document.location.href;
          //document.location.href = "https://www.google.es";
          // si existe el interrogante
          if(loc.indexOf('?')>0)
          {
              // cogemos la parte de la url que hay despues del interrogante
              var getString = loc.split('?')[1];
              // obtenemos un array con cada clave=valor
              var GET = getString.split('&');
              var get = {};
    
              // recorremos todo el array de valores
              for(var i = 0, l = GET.length; i < l; i++){
                  var tmp = GET[i].split('=');
                  get[tmp[0]] = encodeURI(decodeURI(tmp[1]));
              }

          }else{
            var get = {};
            get['h'] = "" ;
          }
    
          if (get['h']!="") {

            this.login.email  = get['h'];         
            this.userData.username = "";
            this.checkmail();


          }else{
          
            
            this.storage.get('email').then((User) => {
              this.login.email  = User;         
            })
          }
    
          if (get['h']!="") {
            this.login.pass  = "";         
          }else{
              this.storage.get('pass').then((Pass) => {
              this.login.password  = Pass;         
              })
          }

          this.storage.get('cookies').then((Coo) => {
            this.userData.cookieshow  = Coo;         
          })
    
    
          this.loginForm = this.fb.group({
            email: new FormControl(this.login.username, Validators.required),
            password: new FormControl(this.login.pass,  Validators.compose([
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])
          )});
    
          this.sinpass="";
    
  }

  ngAfterViewInit() {

    if(this.userData.emailactivo!=''){
 
      this.funciones.checkemail(this.userData.emailactivo).then(
       data => {
            
           this.datosacceso = data;
     
           for (let usu of this.datosacceso) {
     
            if(usu["CLIENTES_EXISTE"]=='SI'){
               this.usuario = usu["CLIENTES_RAZSOCIAL"];
               if(usu["CLIENTES_CONTRASENA"]!=''){
                 this.sinpass = "tiene";
                 this.login.razsocial = usu["CLIENTES_RAZSOCIAL"];
                 this.login.nif = usu["CLIENTES_NIF"];
                 this.login.telefono1 = usu["CLIENTES_TELEFONO1"];
                 this.login.contacto = usu["CLIENTES_CONTACTO"];
                 this.login.direccion = usu["CLIENTES_DIRECCION"];
                 this.login.poblacion = usu["CLIENTES_POBLACION"];
                 this.login.provincia = usu["CLIENTES_PROVINCIA"];
                 this.login.cpostal = usu["CLIENTES_CPOSTAL"];
                 this.login.direccion_envio = usu["CLIENTES_DIRECCION_ENVIO"];
                 this.login.pais = usu["CLIENTES_PAIS"];
                 this.login.pass = usu["CLIENTES_CONTRASENA"];
                 this.login.grupos = usu["CLIENTES_GRUPOS"];
                 if(usu["CLIENTES_GRUPOS"]=="SI"){
                  this.login.grupos = "TODO";
                 }
                 if(usu["CLIENTES_GRUPOS"]=="NO"){
                  this.login.grupos = "SOLO_FACTURAS";
                 }

                 
                 //console.log("VALOR DE LOS GRUPOS:" + this.login.grupos);

               }else{
                 
                 this.sinpass = "notiene";
               }
             }
           }
           
           setTimeout(() => this.ionInput.setFocus(), 300);
       
       });
     
    
    }else{
      
      setTimeout(() => this.ionInput.setFocus(), 300);

    }
  }
  

 
  async apuntame(){

 
              var data = {
                correo: ''
              };

              data.correo  = this.login.email;

              this.funciones.apuntameanewletter(data)
              .then(
                  data => {

                   //console.log(data[0].error);


                    if (data[0].error=="existe") {
                      
                      
                      const alert =  this.alertCtrl.create({
                        header: 'Correo encontrado.',
                        subHeader: 'El correo ya esta incluido en nuestra base de datos.',
                        buttons: [
                          {
                            text: 'Ok'
                          
                          }
                        ]
                      }).then(alert=> alert.present());
  
                    }else{

                    if (data[0].error=="ok") {
                      
                      
                      const alert =  this.alertCtrl.create({
                        header: 'Mensaje enviado.',
                        message: 'Confirme el correo que ha recibido en su cuenta.<br>Gracias por darse de alta con nosotros.',
                        buttons: [
                          {
                            text: 'Ok'
                          
                          }
                        ]
                      }).then(alert=> alert.present());
  
                    }else{

                      const alert =  this.alertCtrl.create({
                        header: 'Error',
                        subHeader: 'Error al enviar el correo de confirmación, por favor verifique que el correo existe.',
                        message: data[0].error,
                        buttons: [
                          {
                            text: 'Ok'
                          
                          }
                        ]
                      }).then(alert=> alert.present());





                    }

                    }

                  }
                )
                .catch(
                  error => {
                    //console.log(error.error.text);

                    const alert =  this.alertCtrl.create({
                      header: 'Error',
                      subHeader: 'Error al enviar el correo de confirmación, por favor verifique que el correo existe.',
                      message: error.error.text,
                      buttons: [
                        {
                          text: 'Ok'
                        
                        }
                      ]
                    }).then(alert=> alert.present());

                  }
                )




}

  
checkmail(){

 this.funciones.checkemail(this.login.email).then(
  data => {
       
      this.datosacceso = data;

      

      for (let usu of this.datosacceso) {

       if(usu["CLIENTES_EXISTE"]=='SI'){
          this.usuario = usu["CLIENTES_RAZSOCIAL"];
          if(usu["CLIENTES_CONTRASENA"]!=''){

            this.sinpass = "tiene";
            this.login.razsocial = usu["CLIENTES_RAZSOCIAL"];
            this.login.nif = usu["CLIENTES_NIF"];
            this.login.telefono1 = usu["CLIENTES_TELEFONO1"];
            this.login.contacto = usu["CLIENTES_CONTACTO"];
            this.login.direccion = usu["CLIENTES_DIRECCION"];
            this.login.poblacion = usu["CLIENTES_POBLACION"];
            this.login.provincia = usu["CLIENTES_PROVINCIA"];
            this.login.cpostal = usu["CLIENTES_CPOSTAL"];
            this.login.direccion_envio = usu["CLIENTES_DIRECCION_ENVIO"];
            this.login.pais = usu["CLIENTES_PAIS"];
            this.login.grupos = "TODO";
 
          }else{

            this.sinpass = "notiene";
          }
        }else{
          alert("El correo no existe en nuestra base de datos. Verifique que es correcto e intendelo de nuevo.");
        }
        break;
      }
      
      if (this.userData.emailactivo=='') {
        setTimeout(() => this.ionInput.setFocus(), 300);
        
      }

  
  });


}


async radioGroupChange(event) {
  //console.log("radioGroupChange",event.detail.value);
  this.login.grupos = event.detail.value;
}


async update() {  

 if (this.login.grupos=='') {
   
  this.login.grupos ="TODO";

 }


  this.funciones.setUpdate(this.login).then(
    data => {
         this.datosacceso = data;
         for (let usu of this.datosacceso) {
            if (usu["RESULTADO"]=="OK"){
              this.acceso();
            }
         }
    });
}

  acceso() { 
    


    this.funciones.getAcceso2(this.login.email, this.login.password).then(
    data => {
         this.datosacceso = data;
         this.usuarioacceso = JSON.stringify(data);

         for (let usu of this.datosacceso) {

           this.userData.username = usu["CLIENTES_RAZSOCIAL"];
           this.userData.emailactivo = usu["CLIENTES_EMAIL"];
           this.userData.CLIENTES_TIPO  = usu["CLIENTES_TIPO"];
           
           //console.log("Esta es la respuesta=" + usu["CLIENTES_RAZSOCIAL"]);
           
           if (usu["CLIENTES_EMAIL"] == this.login.email){
        
             this.userData.logged = true;
             this.userData.setusername(this.userData.username);
             this.userData.setusermail(this.userData.emailactivo); 
             this.userData.CLIENTES_RAZSOCIAL= this.userData.username;

             this.storage.set('email', '');
             this.storage.set('usuario', '');
             this.storage.set('pass', '');

             // set a key/value

            
                         
             this.storage.set('email', this.login.email);
             this.storage.set('usuario', this.userData.username);
             this.storage.set('pass', this.login.password);  

             

             this.userData.emailactivo = this.login.email;
              //CARGA LAS UNIDADES DE LA CESTA
              this.funciones.obtenernumerocesta( this.userData.emailactivo).then(
                data => {
                    this.datoscargados5 = data;
                    for (let dat of this.datoscargados5) {
                    //console.log("Obtiene valorDATA = " + dat["valor"]);

                    this.valorcesta = dat["valor"];

                    this.userData.setValorcesta(dat["valor"]);

                    this.valorcesta = this.userData.getValorcesta();
                }});
        
              this.cerrar()

              } else {

               //console.log("RAZSOCIAL:" + usu["CLIENTES_RAZSOCIAL"]);
               //console.log("EXISTE:" + usu["CLIENTES_EXISTE"]);
               //console.log("ACTIVADO:" + usu["CLIENTES_ACTIVADO"]);

               if (usu["CLIENTES_EXISTE"] == "NO"){

                 alert("El correo no existe en nuestra base de datos. Verifique que es correcto e intendelo de nuevo.");

               } else {

                alert("La contraseña no es correcta.");

               } 
               setTimeout(function() {
                   document.getElementById("demo").innerHTML="";
               }.bind(this), 2500);

         }

       }//fin del for
         
       })
    }

    
  
  cerrar() {
    
    this.modalCtrl.dismiss();

  }
  
  borrar() {
    
    alert("Para borrar sus datos por completo, deberá comunicarlo a nuestra sede central mediante un correo a administracion@tricenter.net.");

  }

}

