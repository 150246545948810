
import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { Config, ModalController, NavParams } from '@ionic/angular';
import { ConferenceData } from '../../providers/conference-data';
import { Router } from '@angular/router';
import { UserData } from '../../providers/user-data';
import { Funciones } from '../../providers/funciones';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'page-favoritos',
  templateUrl: 'favoritos.html',
  styleUrls: ['./favoritos.scss'],
})
export class FavoritosPage implements OnInit {
  

  ios: boolean;

  tracks: {name: string, icon: string, isChecked: boolean}[] = [];

  PROGRAMAS;
  valorabuscar;
  txtbuscar;
  datoscargados;
  paymentAmount: string = '0.00';
  currency: string = 'EUR';
  currencyIcon: string = '€';

  txtaviso: any = { 
    dato1:'',
    dato2: '',
    dato3: ''
  };
  
  
  valorpasado; 

  aray: Array<string>;



  constructor(
    public servicios: Funciones,
    public userdata: UserData,
    public router: Router,
    public confData: ConferenceData,
    private config: Config,
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) {
    
   


  }

 async  ionViewWillEnter() {

    var x = 0;

    let _this = this;

    _this.valorpasado = this.userdata.VALORPASADOPARALISTA;

    

    //console.log("El vaor pasado:" + this.valorpasado);
    
    await this.userdata.ARRAYLISTAS.forEach(function (value) {
     
      x = x + 1;
      
      //console.log("VALOR DE VALUE:" + x + value.LISTAS_NOMBRE + "//" +  value.LISTAS_ESTADO);
      
      _this.servicios.getlistasarticulos(_this.valorpasado,value.LISTAS_CODIGO,"consulta")
      .then(
            data => {
              _this.userdata.ARRAYLISTADELARTICULO = data;

              if (data[0].LISTALIN_ESTADO=="SI"){
                value.LISTAS_ESTADO="true";
           
              }else{
                value.LISTAS_ESTADO="false";
      
              }
              

              


      });
    
    
    
    
    
    
    
    }); 
  
      
  
   }

   ngOnInit(){

    }

    
  selectAll(check: boolean) {
  }


  cerrar() {
    
    this.modalCtrl.dismiss();

    
  }
  
grabar(){

  var x;
  var accion;
  let _this = this;
  var estees;

  _this.valorpasado = this.userdata.VALORPASADOPARALISTA;



  this.userdata.ARRAYLISTAS.forEach(function (value) {
     
    x = x + 1;
    //console.log("Valoe estado " + value.LISTAS_CODIGO + " --> " + x + "-->" + value.LISTAS_ESTADO)
    
    estees = String(value.LISTAS_ESTADO);

    if(estees=="true"){
      accion="insertar";
      //console.log("true --> insertar")
      _this.servicios.getlistasarticulos(_this.valorpasado,value.LISTAS_CODIGO,accion).then(
        data => {
          _this.userdata.ARRAYLISTADELARTICULO = data;
        });
    }else {
      accion="borrar";
      //console.log("false --> borrar")
      _this.servicios.getlistasarticulos(_this.valorpasado,value.LISTAS_CODIGO,accion).then(
        data => {
          _this.userdata.ARRAYLISTADELARTICULO = data;
      });

    }
    
  });

  this.cerrar();
}


selpro(valor){

     // this.userData.programador(this.login.username);

      this.router.navigateByUrl('/app/tabs/estado');


}


}
