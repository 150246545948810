
import { Component, ViewChild, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Config, ModalController, NavParams,AlertController } from '@ionic/angular';
import { ConferenceData } from '../../providers/conference-data';
import { Router } from '@angular/router';
import { UserData } from '../../providers/user-data';
import { Funciones } from '../../providers/funciones';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { async } from '@angular/core/testing';
import { Console } from 'console';
declare var Stripe;


@Component({
  selector: 'page-aviso',
  templateUrl: 'aviso.html',
  styleUrls: ['./aviso.scss'],
})

export class AvisoPage implements OnInit {

  
  stripe = Stripe('pk_live_tYWFxksfBIZ1lHXP4prz0p7I00T2sDQPjU');
  card: any;


  httpHeader: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      "responseType": "text"
  }
}

  httpHeader2: {
    headers: {
      'Content-Type': 'application/json'
  }
}

 httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };

  ios: boolean;

  tracks: {name: string, icon: string, isChecked: boolean}[] = [];

  PROGRAMAS;
  valorabuscar;
  txtbuscar;
  datoscargados;
  paymentAmount: string = '0.00';
  currency: string = 'EUR';
  currencyIcon: string = '€';

  txtaviso: any = { 
    dato1:'',
    dato2: '',
    dato3: ''
  };
  
  importe;
  handler:any = null 



  constructor(
    private http: HttpClient,
    public alertCtrl: AlertController,
    public servicios: Funciones,
    public userdata: UserData,
    public router: Router,
    public confData: ConferenceData,
    private config: Config,
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) {

   
   

   }




   ionViewWillEnter() {

    this.paymentAmount= this.importe;
    let _this = this;
    let _emailactivo = this.userdata.emailactivo;
    let _razsocial = this.userdata.CLIENTES_RAZSOCIAL;
    let _telefono = this.userdata.CLIENTES_TELEFONO1;
    let _nif= this.userdata.CLIENTES_NIF;
    let _direccion = this.userdata.CLIENTES_DIRECCION;
    let _cpostal = this.userdata.CLIENTES_CPOSTAL;
    let _poblacion = this.userdata.CLIENTES_POBLACION;
    let _provincia= this.userdata.CLIENTES_PROVINCIA;

    let _direccion_entrega = this.userdata.CLIENTES_DIRECCION_ENTREGA;
    
  /* 
    setTimeout(() => {
      <any>window['paypal'].Buttons({
        createOrder: function(data, actions) {
          return actions.order.create({
    
              payer: {
                  name: {
                      given_name: "Tricenter",
                      surname: "Pago Web",
                  },
                  phone: {
                    phone_type:"MOBILE",
                    phone_number:{
                      national_number: _telefono,
                    },
                 

                  },
                  
                  email_address: _emailactivo,
                  address: {
                      address_line_1: _direccion,
                      address_line_2: _direccion_entrega,
                      country_code: 'ES',
                      postal_code:_cpostal,
                      admin_area_1:_provincia,
                      admin_area_2:_poblacion
                  }
              },
              purchase_units: [{
                amount: {
                  value: _this.paymentAmount,
                },
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            },
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(
           function(details) {
         
           
            
            //alert('Transaction completed by ' + details.payer.name.given_name);
            ////console.log('Transaction completed by ' + JSON.stringify({details}));
            ////console.log('JSOON:' + JSON.stringify({orderID: data.orderID}));
            ////console.log('Data:' + JSON.stringify(data));
    
            document.getElementById('pago_correcto').style.display = 	"inline";
            document.getElementById('morbid').style.display = "none";
    
            //console.log("****************************************");
            //console.log("Email:" + _emailactivo );
            //console.log("Email:" + details.payer.email_address);
            //console.log("Order:"+ data.orderID);
            //console.log("status:" +  details.status );
            
            
            // Call your server to save the transaction
            return fetch('https://www.tricenter.es/recursos/cesta.php/paypal-transaction-complete', {
              method: 'post',
              body: JSON.stringify({
                  orderID: data.orderID, 
                  email: _emailactivo,
                  //time: details.create_time, 
                  status: details.status
                  //nombre: details.payer.name.given_name, 
                 // prenom: details.payer.name.surname, 
                  //pays: details.payer.address.country_code, 
                  //valeur:details.purchase_units[0].amount.value
              })
            });
            
          });
        },
        style: {
          layout: 'horizontal',
          tagline: 'false'
      }
      }).render('#paypal-button-container');
  
  }, 500)
      
  
*/


}

   ngOnInit(){

    //this.setupStripe();


    }

    setupStripe() {
/*      let _this = this;
      let _emailactivo = this.userdata.emailactivo;

      let elements = this.stripe.elements();
      var style = {
        base: {
          color: '#32325d',
          lineHeight: '24px',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
  
      this.card = elements.create('card', { style: style });
      //console.log("ESTA ES LAS TARJETA:" + this.card);
      this.card.mount('#card-element');
  
      this.card.addEventListener('change', event => {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
  
      var form = document.getElementById('payment-form');
      form.addEventListener('submit', event => {
        event.preventDefault();
        //console.log(event)
  
        this.stripe.createSource(this.card).then(async result => {
          if (result.error) {
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {

            //console.log("eL RESULTADO1:" +  JSON.stringify(result));
            
            //console.log("eL RESULTADO2:" +  result.source.id);
          
              // Call your server to save the transaction
              var importe = Number(this.paymentAmount) * 100;

              return fetch('https://www.tricenter.es/recursos/pedidos.php/stripe/' + importe + '/' + result.source.id, {
                headers: {'Content-Type':'application/x-www-form-urlencoded'}, // this line is important, if this content-type is not set it wont work
                method: 'get',
                })
              .then(function(res){ 
              
                //console.log("AQUI LLEGA?" + res.statusText) 
                
                if (res.statusText=="OK"){
                
                      // Call your server to save the transaction
                      return fetch('https://www.tricenter.es/recursos/cesta.php/paypal-transaction-complete', {
                      method: 'post',
                      body: JSON.stringify({
                          orderID: 'PAGOSTRIPE', 
                          email: _emailactivo,
                          //time: details.create_time, 
                          status: "ok"
                          //nombre: details.payer.name.given_name, 
                          // prenom: details.payer.name.surname, 
                          //pays: details.payer.address.country_code, 
                          //valeur:details.purchase_units[0].amount.value
                      })
                    })
                    .then(function(res2){ 
                      document.getElementById('pago_correcto').style.display = 	"inline";
                      document.getElementById('morbid').style.display = "none";
                    })
                    .catch(function(res){ console.log(res) })
                
                
                }
              
              })


          }
        });
      });
  */
    }
  


    
   
async pagarmediantebanco(){

  var Valores;

  this.servicios.pagomediantebanco().then(
    data => {
 
      Valores =data;
  
      //console.log(data + " Valores operacion:" + Valores[0].RESULTADO);

      if( Valores[0].RESULTADO=="ok" ){
        

        var mensaje ="El pago queda pendiente de realizarse."  ;
        var mensaje2 ="Le hemos enviado un mail con las condiciones.<br>Si no lo recibe pongase en contacto con nostros."  ;
        const alert =  this.alertCtrl.create({
          header: 'Cesta',
          subHeader: mensaje, 
          message: mensaje2 ,
          cssClass: 'alertCustomCss',
      
          buttons: [
            {
              text: 'Si',
              role: 'Si',
              handler: () => { 
                
                this.modalCtrl.dismiss();
                
                
              }
            }]
        }).then(alert=> alert.present());



        
      }

    })
    
  
}


  selectAll(check: boolean) {
  }


  cerrar(data1,data2) {
    
    this.modalCtrl.dismiss();

  }
  

selpro(valor){

     // this.userData.programador(this.login.username);

      this.router.navigateByUrl('/app/tabs/estado');


}

pay2(amount) {   



  this.router.navigateByUrl("/stripe");

  this.modalCtrl.dismiss();

  

}

pay(amount) {    
  let _this = this;
  let _emailactivo = this.userdata.emailactivo;
  
  var handler = (<any>window).StripeCheckout.configure({
    key: 'pk_live_tYWFxksfBIZ1lHXP4prz0p7I00T2sDQPjU',
    image: 'https://stripe.com/img/documentation/checkout/marketplace.png',    
    locale: 'auto',
    token: async function (token: any) {

    // Call your server to save the transaction
    var importe = Number(amount) * 100;

      //console.log("el tocken:" + JSON.stringify(token));

    return fetch('https://www.tricenter.es/recursos/pedidos.php/stripe/' + importe + '/' + token.id, {
     headers: {'Content-Type':'application/x-www-form-urlencoded'}, // this line is important, if this content-type is not set it wont work
      method: 'get',
      })
    .then(function(res){ 
    
      //console.log(res.statusText) 
      
      if (res.statusText=="OK"){
      
           // Call your server to save the transaction
           return fetch('https://www.tricenter.es/recursos/cesta.php/paypal-transaction-complete', {
            method: 'post',
            body: JSON.stringify({
                orderID: 'PAGOSTRIPE', 
                email: _emailactivo,
                //time: details.create_time, 
                status: "ok"
                //nombre: details.payer.name.given_name, 
               // prenom: details.payer.name.surname, 
                //pays: details.payer.address.country_code, 
                //valeur:details.purchase_units[0].amount.value
            })
          })
          .then(function(res2){ 
            document.getElementById('pago_correcto').style.display = 	"inline";
            document.getElementById('morbid').style.display = "none";
          })
          .catch(function(res){ console.log(res) })
      
      
      }
    
    })

    
    .catch(function(res){ console.log(res) })
  
  }})
  
  handler.open({
    
    name: 'Pago del Pedido',
    description: 'Realice el pago ahora.',
    amount: amount * 100,
    currency: "eur"
  
  });



}

}
