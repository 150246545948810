import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';
import { AlertController, IonList, LoadingController, ModalController, Config } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { LoginPage } from './pages/login/login';

import { UserData } from './providers/user-data';
import { Funciones } from './providers/funciones';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Login',
      url: 'login',
      icon: 'person-circle-outline',
      desc: 'Identificate para ver todas las opciones'
    }
   
    /*,
    {
      title: 'Registro de actividad',
      url: '/app/tabs/registro',
      icon: 'list-box',
      desc: 'Histórico de Limpiezas'
    }
    */
  ];
  loggedIn = false;
  dark = false;
  structure: any = {lower: 0, upper: 1000};
  arttemas2;
  arttemas = JSON.parse('[{"ARTICULOS_TEMA": "","ARTICULOS_IMAGEN": "","ARTICULOS_PROVEEDOR": ""}]');  


  constructor(
    public funciones: Funciones,
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    public userdata: UserData,
    public modalCtrl: ModalController,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController
  ) {
    this.initializeApp();

    this.userdata.valores=this.userdata.getGET();

    
    
  }

  loadStripe() {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
}

  async ngOnInit() {

    this.loadStripe();

   /* this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        showCloseButton: true,
        position: 'bottom',
        closeButtonText: `Reload`
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
    */
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.loadData();
    });
  }

/*
  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }
  */

 menuClosed() {

  //console.log("De " + this.structure.lower + " a " + this.structure.upper);
  this.userdata.setLower(this.structure.lower);
  this.userdata.setUpper(this.structure.upper);

     //this.events.publish('menu:closed', '');
}

openPage(subtema) {

  //console.log('Abrir los subtemas:' + subtema.ARTICULOS_TEMA);

  this.userdata.tema = subtema.ARTICULOS_TEMA;
  this.userdata.subtema="";
  
  this.userdata.setimagentema(subtema.ARTICULOS_IMAGEN);

  this.storage.set('listas', '');
  this.storage.set('tema', '');
  this.storage.set('subtema', '');
  this.storage.set('tema', subtema.ARTICULOS_TEMA);

  this.funciones.getsubtema(subtema.ARTICULOS_TEMA)
  .then(
      data => {
        //console.log(this.userdata.ARRAYSUBTEMAS + "");
        this.userdata.ARRAYSUBTEMAS = data;
        this.userdata.cerrarcookie();
        this.menu.toggle();
        this.router.navigateByUrl('/subfamilia');     
       }
    )
    .catch(
      error => {
        //console.log(error);
      }
    )
       
}

loadData(){
 var proveedor;
 this.funciones.gettema()
    .then(
      data => {
        this.arttemas2 = data;
        this.arttemas = [];
        for (let i = 0; i < this.arttemas2.length; i++) {
          if (this.arttemas2[i].ARTICULOS_PROVEEDOR=="liderpapel") {

            this.arttemas.push({
              'ARTICULOS_TEMA' : this.arttemas2[i].ARTICULOS_TEMA ,
              'ARTICULOS_IMAGEN' : this.arttemas2[i].ARTICULOS_IMAGEN ,
              'ARTICULOS_PROVEEDOR' : this.arttemas2[i].ARTICULOS_PROVEEDOR ,
            })
          }    
        }

        for (let i = 0; i < this.arttemas2.length; i++) {
          if (this.arttemas2[i].ARTICULOS_PROVEEDOR!="liderpapel") {

            this.arttemas.push({
              'ARTICULOS_TEMA' : this.arttemas2[i].ARTICULOS_TEMA ,
              'ARTICULOS_IMAGEN' : this.arttemas2[i].ARTICULOS_IMAGEN ,
              'ARTICULOS_PROVEEDOR' : "mercatoner" ,
            })
          }    
        }
      }
    )
    .catch(
      error => {
        //console.log(error);
      }
    )
}


async openListas(lista){

  this.storage.set('subtema', '');
  this.storage.set('buscar2', '');
  this.storage.set('listas', lista);
  this.userdata.cerrarcookie();
  this.userdata.titulolistas = lista;


  //console.log("Console pinta emailactico:" + this.userdata.emailactivo)

  if (lista=="favoritos") {

    if (this.userdata.emailactivo==""){

    const modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {  }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    

    }

  }
  this.funciones.getlistapredefinida(lista).then(
    data => {

  
      this.userdata.ARRAYARTICULOS = data;
      this.userdata.textobuscar='';
      this.userdata.posicion=0;
      this.userdata.posicion=0;
      this.userdata.ARRAYVALORBUSQUEDA=[];
      for (let x = 0; x < 20; x++) {
        if ( this.userdata.posicion < this.userdata.ARRAYARTICULOS.length){
          this.userdata.ARRAYVALORBUSQUEDA.push( this.userdata.ARRAYARTICULOS[x] );
          this.userdata.posicion++;

        }else {
          break;
        }
      } // fin for

      this.userdata.esunalista ="si";
  
      this.menu.toggle();  
      this.router.navigateByUrl('/listaart');


    } // fin else
    )
  .catch(
    error => {
      //console.log(error);
    }
  )
 

}


}
