import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController,  LoadingController, ModalController, ToastController, Config , IonList } from '@ionic/angular';
import { UserData } from '../../providers/user-data';
import { Funciones } from '../../providers/funciones';
import { ConferenceData } from '../../providers/conference-data';
import { IonSlides,IonContent } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CKEditorModule } from 'ckeditor4-angular';
import { async } from 'rxjs/internal/scheduler/async';
//import { PayPal, PayPalPayment, PayPalConfiguration } from '@ionic-native/paypal/ngx';
import { AvisoPage } from '../aviso/aviso';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform, NavParams, NavController } from '@ionic/angular';



@Component({
  selector: 'page-noticia',
  templateUrl: 'noticia.html',
  styleUrls: ['./noticia.scss'],
})

export class NoticiaPage {

ID;
datos;
i;
urlcompartir;
noticia;
 txtlista: any = { 
    unidades:''
  };

  constructor(

    public platform: Platform, params: NavParams,
    private http: HttpClient,
    //private payPal: PayPal,
    private storage: Storage,
    public alertCtrl: AlertController,
    public confData: ConferenceData,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    public router: Router,
    public toastCtrl: ToastController,
    public servicios: Funciones,
    public userdata: UserData,
    public config: Config
)
{
  
  this.datos= params.get('Datos');
  this.i = params.get('Lugar');

  this.urlcompartir  ="https://www.tricenter.es/#/noticias?id=" + this.i;

  this.noticia = this.datos.NOTICIAS_ARTICULO;
  
  this.noticia = String(this.noticia).replace(/<br>/g,"\r\n");


  

}
 

async copiar() {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(this.urlcompartir);
    } catch (err) {}
  }
}

open(){

let filePath: string = 'https://www.tricenter.es/APP/tricenter/resources/logo_trans.png';


}
  


subeimagen(){



}

onFileChoose($event): void {

}

getFileInfo(event: Event): Promise<any> {

  let target = event && event.target;
  let files: Array<File> = target && target['files'];

  //console.log(files[0].type);
  //console.log(files[0].name);

  return new Promise((resolve, reject) => {
    if (files && files.length) {
      files = Array.from(files);
      let fileName = files[0].name;
      let fileSize = files[0].size;
      let fileType = files[0].type;
      let fileReader = new FileReader();

      fileReader.onload = () => resolve({
        fileData: fileReader.result,
        fileName: fileName,
        fileSize: fileSize,
        fileType: fileType
      });

      fileReader.onerror = error => reject(error);

      fileReader.onabort = error => reject(error);

      fileReader.readAsDataURL(files[0])
    }
  });
}













cerrar() {
    
  this.modalCtrl.dismiss();

}







}
