import { AfterViewInit, Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AlertController,  LoadingController, ModalController, ToastController, Config , IonList } from '@ionic/angular';
import { ConferenceData } from '../../providers/conference-data';
import { Router } from '@angular/router';
import { UserData } from '../../providers/user-data';
import { Funciones } from '../../providers/funciones';
import { LoginPage } from '../../pages/login/login';
import { FavoritosPage } from '../../pages/favoritos/favoritos';


@Component({
  selector: 'page-selecc',
  templateUrl: 'selecc.html',
  styleUrls: ['./selecc.scss'],
})
export class SeleccPage implements AfterViewInit {
  ios: boolean;

  tracks: {name: string, icon: string, isChecked: boolean}[] = [];

  notieneimagen: string = 'https://www.tricenter.es/recursos/imgs/no-foto.gif';

  PROGRAMAS;
  valorabuscar;
  txtbuscar;
  datoscargados;

  temaactivo;
  subtemaactivo;
  
  valorpasado; 
  
  codigo;
  unidades;
  precio;
  todayDate = new Date();
  fecha = (this.todayDate.getFullYear() + '' + ((this.todayDate.getMonth() + 1)) + '' + this.todayDate.getDate() + '' +this.todayDate.getHours() + '' + this.todayDate.getMinutes()+ '' + this.todayDate.getSeconds());
  excludeTracks: any = [];

  itemsInCart: Object[] = [];
  datoscargados2;
  datoscargados3;
  txtcesta: any = { 
    email:'',
    razsocial: '',
    telefono: '',
    nif : '',
    direccion: '',
    cpostal: '',
    poblacion: '',
    provincia: '',
    pais: '',
    observaciones: '',
    direccion_entrega:'',
    forma_envio:'',
    cupon:''
  };

  txt: any = {
    texto:  '',
    lower:  '0',
    upper:  '1000', 
    marca:  'todas',
    tipo:   'todos',
    color : 'todos',
    tamano: 'todos',
    gramaje:'todos',
    orden:  'Descripción'
  };

  text: string='Flamenco'
  imgurl:string= 'https://cdn.pixabay.com/photo/2019/12/26/05/10/pink-4719682_960_720.jpg'
  link: string='https://link.medium.com/JA4amAHFJ5'
  
  constructor(

    public alertCtrl: AlertController,
    public servicios: Funciones,
    public userdata: UserData,
    public router: Router,
    public confData: ConferenceData,
    private config: Config,
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) { }


  async copiar() {
    if (navigator.clipboard) {
      try {
        alert("Link Copiado");
        await navigator.clipboard.writeText("https://www.tricenter.es/#/listaart?a=" + this.datoscargados[0].ARTICULOS_REFERENCIA_PROVEEDOR);

      } catch (err) {

        alert(err);

      }
    }
  }
  
  

  ionViewWillEnter() {


    this.txt.texto = this.userdata.buscar;

    this.servicios.getBuscar(this.txt).then(
      data => {
          this.datoscargados = data;
          ////console.log("Obtiene valor = " + data);
      })
      .catch(
        error => {
          //console.log(error);
        }
      )

  }


  // TODO use the ionViewDidEnter event
  ngAfterViewInit() {
    // passed in array of track names that should be excluded (unchecked)
  }

  selectAll(check: boolean) {
  }



  async favoritos(valor){
  
    var accion;
    let _this = this;
    
    this.userdata.VALORPASADOPARALISTA = valor;
  
    _this.valorpasado = this.userdata.VALORPASADOPARALISTA;
    
    if (this.userdata.emailactivo.indexOf("tricenter.net",0)!="-1") {
  
          //console.log("AQUI LO LO LANZA" + valor);
    
          const modal =  this.modalCtrl.create({
            component: FavoritosPage
          }).then( modal => modal.present())
            
        } else {
  
          _this.servicios.getlistasarticulos(_this.valorpasado,"favoritos","consulta").then(
              data => {
                _this.userdata.ARRAYLISTADELARTICULO = data;
            
                var mensaje;
            
  
                if (data[0].LISTALIN_ESTADO=="SI"){
                 
                  _this.servicios.getlistasarticulos(this.valorpasado,"favoritos","borrar").then(
                    data => {
            
                      this.userdata.ARRAYLISTADELARTICULO = data;
  
                      mensaje="El articulo ha sido <b>quitado</b> de la lista de favoritos con éxito.<br>Verá el cambio cuando vuelva a consurtar sus favoritos.";
                      const alert =  this.alertCtrl.create({
                        header: 'Favoritos',
                        message: mensaje ,
                        cssClass: 'alertCustomCss',
                        buttons: [
                          {
                            text: 'Ok',
                            role: 'No',
                            handler: () => { console.log('No hacer nada');  }
                          
                          }]
                       
                      }).then(alert=> alert.present());
                    });
             
                }else{
                  
                  _this.servicios.getlistasarticulos(this.valorpasado,"favoritos","insertar").then(
                    data => {
                     
                      this.userdata.ARRAYLISTADELARTICULO = data;
  
                      mensaje="El articulo ha sido <b>añadido</b> a la lista de  favoritos con éxito.<br>Verá el cambio cuando vuelva a consultar sus favoritos.";
                      const alert =  this.alertCtrl.create({
                        header: 'Favoritos',
                        message: mensaje ,
                        cssClass: 'alertCustomCss',
                        buttons: [
                          {
                            text: 'Ok',
                            role: 'No',
                            handler: () => { console.log('No hacer nada');  }
                          
                          }]
                       
                      }).then(alert=> alert.present());
                    });
  
  
                }
  
              
               
                
  
  
        });
         
    
      }
  
  }
  





  async openArticulo(value) { 
    

    this.userdata.setBuscar(value);
    const modal = await this.modalCtrl.create({
      component: SeleccPage
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    //this.nosubas='nosubas';
    //this.ngOnInit();
     
} 

  cerrar(data1,data2) {
    
    this.userdata.PROGRAMADOR = data1;
    
    this.userdata.NOMBRE_PROGRAMADOR = data2;

    this.modalCtrl.dismiss(data1);
  }
  

selpro(valor){

     // this.userData.programador(this.login.username);

      this.router.navigateByUrl('/app/tabs/estado');


}


async cesta(datos) {

  //console.log("manda a ala cesta1");

  //console.log("esate es:" + this.userdata.emailactivo);
  //var data = {};
  if (this.userdata.emailactivo){
    
    this.codigo = datos.ARTICULOS_REFERENCIA_PROVEEDOR;
    this.unidades = datos.ARTICULOS_UNIDADES;

    if (datos.ARTICULOS_OFERTA!="0.00") {
      this.precio = datos.ARTICULOS_OFERTA;
    }else{
      this.precio = datos.ARTICULOS_PVP_IVA;
    }

  

            var arr_cesta = {} ;

            arr_cesta["cesta_codigo"] = datos.ARTICULOS_REFERENCIA_PROVEEDOR;
            arr_cesta["cesta_descripcion"] = datos.ARTICULOS_DESNORMAL
            arr_cesta["cesta_fecha"] = this.fecha;
            arr_cesta["cesta_unidades"] = datos.ARTICULOS_UNIDADES;

            if (datos.ARTICULOS_OFERTA!="0.00") {
              arr_cesta["cesta_precio"] = datos.ARTICULOS_OFERTA;
            }else{
              arr_cesta["cesta_precio"] = datos.ARTICULOS_PVP_IVA;
            }
            arr_cesta["cesta_email"] = this.userdata.emailactivo
            
    
            this.servicios.addCesta(arr_cesta).then(
              data => {
               this.datoscargados2 = data;
               for (let dat of this.datoscargados2) {
                  //console.log("Obtiene valorDATA = " + dat["RESULTADO"]);
                   
                  if (dat["RESULTADO"]=="OK"){
                    this.servicios.obtenernumerocesta( this.userdata.emailactivo).then(
                    data => {
                      this.datoscargados3 = data;
                        for (let dat of this.datoscargados3) {
                        this.userdata.setValorcesta(dat["valor"]);
                        }
                        var mensaje;
                        mensaje="El artículo ha sido añadido a la cesta.";

                        const alert =  this.alertCtrl.create({
                          header: 'Cesta',
                          message: mensaje ,
                          cssClass: 'alertCustomCss',
                      
                          buttons: [
                            {
                              text: 'Ok',
                              role: 'Ok',
                              handler: () => { console.log('No hacer nada');  }
                            }]
                          }).then(alert=> alert.present());
                    
                        this.modalCtrl.dismiss();

                      })
                  } // fin if
                 } // fin for
                })
              .catch(
                error => {
                  //console.log(error);
              }
            )



    
  } else {



    //this.navCtrl.push(AreaclientePage);
    const modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: { excludedTracks: this.excludeTracks }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
   


    if (this.userdata.emailactivo!="") {
      
      this.cesta(datos)


    }


    
  }
} // fin cesta



imageLoaded(event) {
  // Register the onerror event on the image in case of a 404
  event.srcElement.src = this.notieneimagen;
}



}
