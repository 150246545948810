import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UserData {
  _favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

  public FILTROSMARCAS;
  public FILTROSTIPOS;
  public FILTROCOLORES;
  public FILTROTAMANOS;
  public FILTROGRAMAJES; 

  public VALORMARCAS;
  public VALORTIPOS;
  public VALORCOLORES;
  public VALORTAMANOS;
  public VALORGRAMAJES; 
  public VALORORDEN; 
  public ARRAYLISTAS;
  public ARRAYLISTADELARTICULO;
  public ARRAYVALORBUSQUEDA;
  public ARRAYPEDIDOS;
  public ARRAYFACTURAS;
  public ARRAYSUBTEMAS;
  public ARRAYARTICULOS;
  public ARRAYOFERTAS;
  public ARRAYVENDIDOS;
  public ARRAYNOTICIAS;
  public PROGRAMAS;
  public PROGRAMADOR  = '';
  public NOMBRE_PROGRAMADOR  = '';
  public nombre;
  public user;
  public pass;
  public pages: Array<{title: string, visible: string}>;
  public cargandoshow;

  public APPSELECT  ="pica";

  public VALORPASADOPARALISTA;

  public posicion;

  public lupa: boolean = false;
  public tema;
  public subtema;
  public textobuscar;
  
  private imagentema;

  public pagocorrecto;
  private NumeroPedido;
  private HoraPedido;
  private ImportePedido;
  
  private lisart;
  public logged;
  public username;
  public emailactivo; // no modificable
  public nombreemailactivo; // no modificable
  public pagina_atras;
  public tema_atras;
  public subtema_atras;
    
  public CLIENTES_CONTACTO;
  public CLIENTES_RAZSOCIAL;
  public CLIENTES_CONTRASENA;
  public CLIENTES_TELEFONO1;
  public CLIENTES_DIRECCION;
  public CLIENTES_POBLACION;
  public CLIENTES_PROVINCIA;
  public CLIENTES_CPOSTAL;
  public CLIENTES_NIF;
  public CLIENTES_EMAIL;
  public CLIENTES_DIRECCION_ENTREGA;
  public CLIENTES_CUPON;
  public CLIENTES_TIPO;
  public esunalista;

  public usuarioregistroarray;
  public buscar;
  public buscar2;

  public lower;
  public upper;

  public valorcesta;

  public tokenGuardado = null;
  public cookieshow;
  
  public titulolistas; 
  public cabeceralistas;

  public clockDisplay: string;
  
  valorpasado; 
  seconds;
  minutes;
  hours;
  interval: number;
  inicio;
  fin;
  public hoy;

  public valores;
  
  constructor(
    public toastCtrl: ToastController,
    public storage: Storage
  ) { 

    this.clockDisplay="";
    this.logged = "";
    this.user = "";
    this.nombre = "";
    this.pass="";

    this.CLIENTES_RAZSOCIAL = "";

    this.tema = "";
    this.imagentema="";
    this.subtema = "";
    this.lisart = "";
    this.logged = "";
    this.username = "";
    this.emailactivo = "";
    
    this.CLIENTES_CONTACTO = "";
    this.CLIENTES_RAZSOCIAL = "";
    this.CLIENTES_CONTRASENA = "";
    this.CLIENTES_TELEFONO1 = "";
    this.CLIENTES_DIRECCION = "";
    this.CLIENTES_POBLACION = "";
    this.CLIENTES_PROVINCIA = "";
    this.CLIENTES_CPOSTAL = "";
    this.CLIENTES_NIF = "";
    this.CLIENTES_EMAIL = "";
    this.CLIENTES_DIRECCION_ENTREGA = "";
    this.CLIENTES_CUPON = "";
    this.CLIENTES_TIPO = "";
    this.NumeroPedido ="";
    this.HoraPedido ="";
    this.ImportePedido ="";

   // this.tokenGuardado = this.fcm.ValorToken;
  }


    
  

    
  setNumeroPedido(value){
    this.NumeroPedido = value;
   }
   
   getNumeroPedido(){
    return this.NumeroPedido;
   }

  setFechaPedido(value){
    this.HoraPedido = value;
   }
   
   getFechaPedido(){
    return this.HoraPedido;
   }

  setImportePedido(value){
    this.ImportePedido = value;
   }
   
   getImportePedido(){
    return this.ImportePedido;
   }

   setRegistro(value){
    this.usuarioregistroarray = value;
   }
   
   getRegistro(){
    return this.usuarioregistroarray;
   }


   setValorcesta(value){
    this.valorcesta = value;
   }
   
   getValorcesta(){
    return this.valorcesta;
   }

   setUpdate(value){
    this.usuarioregistroarray = value;
    this.CLIENTES_CONTACTO = this.usuarioregistroarray["nombre"];
    this.CLIENTES_RAZSOCIAL = this.usuarioregistroarray["razsocial"];
    this.CLIENTES_CONTRASENA = this.usuarioregistroarray["contrasena"];
    this.CLIENTES_TELEFONO1 = this.usuarioregistroarray["telefono"];
    this.CLIENTES_DIRECCION = this.usuarioregistroarray["direccion"];
    this.CLIENTES_POBLACION = this.usuarioregistroarray["poblacion"];
    this.CLIENTES_PROVINCIA = this.usuarioregistroarray["provincia"];
    this.CLIENTES_CPOSTAL = this.usuarioregistroarray["cpostal"];
    this.CLIENTES_NIF = this.usuarioregistroarray["nif"];
    this.CLIENTES_DIRECCION_ENTREGA = this.usuarioregistroarray["entrega"];
    this.CLIENTES_EMAIL = this.emailactivo;
   }
   
   getUpdate(){
    return this.usuarioregistroarray;
   }

   setLogged(value){
    this.logged = value;
   }
   
   getLogged(){
    return this.logged;
   }
   
   setusername(value){
     this.username = value;
   }

   getusername(){
     return this.username;
   }

   setusermail(value){
      this.emailactivo = value;
   }

    getusermail(){
     return this.emailactivo;
   }

   settema(value){
   	this.tema = value;
   }
   
   gettema(){
   	return this.tema;
   }

   setimagentema(value){
     this.imagentema = value;
   }
   
   getimagentema(){
     return this.imagentema;
   }

   getLower(){
     return this.lower;
   }

  setLower(value){
     this.lower = value;
   }

   getUpper(){
     return this.upper;
   }

   setUpper(value){
     this.upper = value;
   }

   setBuscar(value){
     this.buscar = value;
   }

   getBuscar(){
     return this.buscar;
   }

   setBuscar2(value){
     this.buscar2 = value;
   }

   getBuscar2(){
     return this.buscar2;
   }

   setsubtema(value){
   	this.subtema = value;
   }
  
   getsubtema(){
   	return this.subtema;
   }

   setlisart(value){
     this.lisart = value;
   }
   
   getlisart(){
     return this.lisart;
   }

  rellenaconceros(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }
}


getGET()
{
    // capturamos la url
    var loc = document.location.href;

    //document.location.href = "https://www.google.es";

    // si existe el interrogante
    if(loc.indexOf('?')>0)
    {
        // cogemos la parte de la url que hay despues del interrogante
        var getString = loc.split('?')[1];
        // obtenemos un array con cada clave=valor
        var GET = getString.split('&');
        var get = {};

        // recorremos todo el array de valores
        for(var i = 0, l = GET.length; i < l; i++){
            var tmp = GET[i].split('=');
            get[tmp[0]] = encodeURI(decodeURI(tmp[1]));
        }
        return get;
    }
}


async lanzacookie(){

  //console.log("entra en lanza cookie:" +  this.cookieshow );

  if (this.cookieshow!="aceptada"){
    const toast = await this.toastCtrl.create({
      header: 'POLÍTICA SOBRE COOKIES',
      message: 'Esta web no utiliza cookies propias ni de terceros para garantizar el correcto funcionamiento de esta, recoger información sobre su uso, mejorar nuestros servicios y mostrarte publicidad personalizada basándonos en el análisis de tu tráfico.<br><br> Para más información pinche aquí : <a target="" href="https://www.tricenter.es/#/politicas?p=cookie">Política de cookies</a> <br>.' ,
      color:'light',
      cssClass:"my-toast",
      position: 'bottom',
      buttons: [
        {
          text: 'Acepto',
          role: 'cancel',
          handler: () => {
            this.storage.set('cookies', 'aceptada');
          }
        }
      ]
    });
    toast.present();
    this.cookieshow = true;
  }else{
    //console.log("ENTRA AQUI");
    this.toastCtrl.dismiss(0);  
  }
  
}

 cerrarcookie(){


  if (this.cookieshow==true) {
    this.cookieshow = false;

    //console.log("Como la coockiue:" + this.cookieshow); 
    
    this.toastCtrl.dismiss(0);  
    
  }
  
  
}

async lanzacargando(valor){
 /* const toast = await this.toastCtrl.create({
    message: '(' + valor + ')' + 'Buscando, por favor espere ... <br><ion-chip><ion-spinner></ion-spinner></ion-chip>' ,
    color:'light',
    cssClass:"my-toast",
    position: 'middle',
    duration: 2000,
  });
  toast.present();
  */
  this.cargandoshow = true;

  
}

async muestramsg(mensaje,tiempo){
  
  const toast = await this.toastCtrl.create({
    message:  mensaje ,
    color:'danger',
    position: 'middle',
    duration: tiempo,
  });
  toast.present();


  
}


 cerrarcargando(valor){
   
    this.cargandoshow = false;
    //this.toastCtrl.dismiss('0');  
    

 
  
}




restarHoras() {
    
  var date = new Date();

  

  this.inicio = new Date(date.getTime());

  this.hoy = this.inicio.getFullYear()  + ("0" + (this.inicio.getMonth() + 1)).slice(-2) + ("0" + (this.inicio.getDate())).slice(-2);
  //console.log("Hoy es el dia:" + this.hoy);
  this.inicio = this.inicio.getHours() + ":" + this.inicio.getMinutes();
  this.fin = "23:59";
  
  var inicioMinutos = parseInt(this.inicio.substr(3,2));
  var inicioHoras = parseInt(this.inicio.substr(0,2));
  
  var finMinutos = parseInt(this.fin.substr(3,2));
  var finHoras = parseInt(this.fin.substr(0,2));

  var transcurridoMinutos = finMinutos - inicioMinutos;
  var transcurridoHoras = finHoras - inicioHoras;
  
  if (transcurridoMinutos < 0) {
    transcurridoHoras--;
    transcurridoMinutos = 60 + transcurridoMinutos;
  }
  
  var horas = transcurridoHoras.toString();
  var minutos = transcurridoMinutos.toString();
  
  if (horas.length < 2) {
    horas = "0"+horas;
  }

  
  //console.log("Las horas mas los minutos:" + (transcurridoHoras*60) + "/" + transcurridoMinutos );

  this.countdowntimer( 60*((transcurridoHoras*60) + transcurridoMinutos))

}

countdowntimer(duration) {

if (duration > 0) {
  
  if(this.clockDisplay==""){
    setInterval(() => {
      duration = duration - 1;
      if (duration <= -1) {
        return;
      }
      if (duration % 60 < 10) {
        this.seconds = '0' + parseInt("" + duration % 60);
      } else {
        this.seconds = '' + parseInt((duration % 60).toString());
      }
      if (duration / 60 < 10) {
        this.minutes = '0' + parseInt("" + duration / 60, 10);
      } else {
        this.minutes = '' + parseInt((duration / 60).toString(), 10);
      }
      if (duration / 3600 < 10) {
        this.hours = "" + parseInt("" + duration / 3600);
      } else {
        this.hours = "" + parseInt((duration / 3600).toString())
      }
      if (this.minutes >= 60) {
        this.minutes = parseInt("" + this.minutes % 60);
      }
      this.clockDisplay = ("0" + (this.hours)).slice(-2) + ":" + ("0" + (this.minutes)).slice(-2) + ":" + ("0" + (this.seconds)).slice(-2) ;
      if (this.hours == 0 && this.minutes == 0 && this.seconds == 0) {
        this.clockDisplay="FIN";
      }
     // if (this.hours == 0 && this.minutes == 0 && this.seconds == 1) {
     //   this.seconds=0;
     // }
    }, 1000);
  }
}else  {

  this.clockDisplay="FIN";
  

}
}    


}
